<template>
  <div
    class="statistic-container mt-2_5em"
    data-testid="container-webrtc-statistics"
  >
    <v-layout row wrap data-testid="container-data-received-chart">
      <v-flex xs12>
        <div class="subheading pb-1">Data received chart</div>
        <GChart
          v-if="chartData.length > 1"
          type="LineChart"
          :data="parsedChartData['dReceived']"
          :options="drChartOptions"
          :resizeDebounce="500"
          ref="drCh"
          :id="'drCh' + identifier"
          :key="'keyChart' + identifier"
        />
      </v-flex>
    </v-layout>

    <v-layout row wrap class="mt-4" data-testid="container-packet-lost-chart">
      <v-flex xs12>
        <div class="subheading pb-1">Packets lost chart</div>
        <GChart
          type="LineChart"
          :data="parsedChartData['pLost']"
          :options="plChartOptions"
          :resizeDebounce="500"
          @ready="onChartReady"
          ref="plCh"
          :id="'plCh' + identifier"
          :key="'keyChart' + identifier"
        />
      </v-flex>
    </v-layout>

    <v-layout row wrap class="mt-4" data-testid="container-final-results">
      <v-flex xs12>
        <div class="v-table__overflow py-2">
          <table
            class="v-datatable v-table theme--dark testResultTable"
            data-testid="table-final-results"
          >
            <thead>
              <tr class="text-xs-left">
                <th>Parameter</th>
                <!--                    <th>Bandwith</th>-->
                <th>Data received (MBits)</th>
                <th>Packets lost</th>
                <th>Lost (%)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Video</td>
                <!--                    <td>{{ videoBandwith }} KB per second</td>-->
                <td>{{ textVideoBytesReceived }}</td>
                <td>{{ videoPacketsLost }}</td>
                <td>{{ videoPercentPacketLost }}</td>
              </tr>
              <tr>
                <td>Audio</td>
                <!--                    <td>{{ audioBandwith }} KB per second</td>-->
                <td>{{ textAudioBytesReceived }}</td>
                <td>{{ audioPacketsLost }}</td>
                <td>{{ audioPercentPacketLost }}</td>
              </tr>
              <tr>
                <td>Total</td>
                <!--                    <td>{{ totalBandwith }} KB per second</td>-->
                <td>{{ textTotalBytesReceived }}</td>
                <td>{{ totalPacketLost }}</td>
                <td>{{ totalPercentPacketLost }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

const { detect } = require("detect-browser")
import _ from "lodash"
import { GChart } from "vue-google-charts"
import GeneralService from "@/common/general.service"
import {
  percentPacketLost,
  decimalsPercentPacketLost,
} from "@/common/webrtc/helpers"
import PeerConnection from "rtcpeerconnection"

export default {
  name: "webrtc-statistics",

  props: {
    connId: {
      type: String,
    },
    duration: {
      type: Number,
      default: null,
    },
    statsData: {
      type: Object,
      default: null,
    },
    identifier: {
      type: String,
      default: "custom-identifier",
    },
    theme: {
      type: String,
      default: "white",
    },
    saveStatsInStore: {
      type: Boolean,
      default: false,
    },
    watchZeroResult: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
  },

  components: {
    GChart,
  },

  data() {
    return {
      generalChartData: [],
      dataReceivedChartOptions: {
        hAxis: {
          title: "Time (seconds)",
          viewWindowMode: "explicit",
          viewWindow: {
            min: 0,
            max: 10,
          },
          format: "#",
        },
        vAxis: {
          title: "MBits received",
          viewWindow: {
            min: 0,
            max: 10,
          },
          format: "#",
        },
        colors: ["#01579B", "#64DD17"],
        legend: {
          position: "top",
        },
        chartArea: {
          width: "88%",
          right: "3%",
        },
      },
      packetsLostChartOptions: {
        hAxis: {
          title: "Time (seconds)",
          viewWindowMode: "explicit",
          viewWindow: {
            min: 0,
            max: 10,
          },
          format: "#",
        },
        vAxis: {
          title: "Packets lost",
          viewWindowMode: "explicit",
          viewWindow: {
            min: 0,
            max: 10,
          },
          format: "#",
        },
        colors: ["#01579B", "#64DD17"],
        legend: {
          position: "top",
        },
        chartArea: {
          width: "88%",
          right: "3%",
        },
      },

      darkThemeOptions: {
        hAxis: {
          textStyle: { color: "#FFF" },
          titleTextStyle: { color: "#FFF" },
          gridlines: { color: "#616161" },
          baselineColor: "#FFF",
        },
        vAxis: {
          textStyle: { color: "#FFF" },
          titleTextStyle: { color: "#FFF" },
          gridlines: { color: "#616161" },
          baselineColor: "#FFF",
        },
        legend: {
          textStyle: {
            color: "#FFF",
          },
        },
        colors: ["#00B0FF", "#64DD17"],
        backgroundColor: { fill: "transparent" },
      },

      chartData: [
        ["Time", "Stream"],
        [0, 0],
      ],
      chartOptions: {
        chart: {
          title: "Company Performance",
          subtitle: "Sales, Expenses, and Profit: 2014-2017",
        },
        hAxis: {
          title: "Time (seconds)",
        },
        vAxis: {
          title: "MBits received",
        },
        colors: ["#a52714", "#097138"],
      },
      time: 0,
      videoBandwith: 0.0,
      videoBytesReceived: 0.0,
      videoPacketsLost: 0,
      videoPacketsReceived: 0,
      audioBandwith: 0.0,
      audioBytesReceived: 0.0,
      audioPacketsLost: 0,
      audioPacketsReceived: 0,
      prevBytesReceived: {
        video: 0,
        audio: 0,
      },
      intervalObj: null,
      stopGetStats: false,
      getStatsStarted: false,
      stopGetStatsLocal: false,
      progressDuration: 0,
      interval: 2000,
    }
  },

  mounted() {
    let self = this
    self.mountInitialisator()
    self.$root.$on("statistic-zero-finish", (inProgressWorkerTask) => {
      if (
        inProgressWorkerTask &&
        !this.$store.getters.isLastWorkerTask(inProgressWorkerTask) &&
        inProgressWorkerTask.id === this.identifier
      ) {
        self.stopStatistic().then(() => {
          self.fillAllZeroData().then(() => {
            self.$emit("statistic-finished", self.getReadyStatsData())
          })
        })
      }
    })
    self.$root.$on("peer-conn-broken", (inProgressWorkerTask) => {
      // @todo return for WebRTC fail later
      if (
        inProgressWorkerTask &&
        !this.$store.getters.isLastWorkerTask(inProgressWorkerTask) &&
        inProgressWorkerTask.id === this.identifier
      ) {
        self.stopStatistic().then(() => {
          self.fillAllZeroData().then(() => {
            self.$emit("statistic-finished", self.getReadyStatsData())
          })
        })
      }
    })
  },

  beforeDestroy() {
    this.stopStatistic()
  },

  watch: {
    connId: function (newVal) {
      // watch it
      let self = this
      if (newVal) {
        setTimeout(function () {
          self.initStatistics()
        }, 400)
      }
    },

    statsData: function () {
      // in case when test is finished we must refresh chart for updat efor combined result (2 point test)
      this.mountInitialisator()
    },

    generalChartData: function (newVal) {
      // When testing PtoP - if nether of the 2 can receive incoming stream after 20 sec - show an Error
      let arrLength = 20 / (this.interval / 1000) + 1
      if (
        this.watchZeroResult &&
        this.type === "ptop" &&
        newVal.length === arrLength &&
        this.totalBytesReceived === 0
      ) {
        this.$root.$emit("statistic-zero")
      }
    },
  },

  methods: {
    fillAllZeroData() {
      console.log("fillAllZeroData--")
      let self = this
      return new Promise((resolve) => {
        let cData = {
          realTimestamp: null,
          videoBytesReceived: 0,
          audioBytesReceived: 0,
          videoPacketsLost: 0,
          audioPacketsLost: 0,
          videoPacketsReceived: 0,
          audioPacketsReceived: 0,
        }
        let stepDuration = this.duration / this.interval
        for (let i = 0; i <= stepDuration; i++) {
          let newProgress = i * self.interval
          let finded = this.generalChartData.find(
            (d) => d.progress === newProgress
          )
          if (!finded) {
            let cDataCurrent = { ...cData, ...{ progress: newProgress } }
            this.generalChartData.push(cDataCurrent)
          }
        }
        resolve()
      })
    },

    mountInitialisator() {
      let self = this
      if (!_.isNull(self.statsData)) {
        self.videoBytesReceived = self.statsData.tableData.videoBytesReceived
        self.audioBytesReceived = self.statsData.tableData.audioBytesReceived
        self.videoPacketsLost = self.statsData.tableData.videoPacketsLost
        self.audioPacketsLost = self.statsData.tableData.audioPacketsLost
        self.videoPacketsReceived =
          self.statsData.tableData.videoPacketsReceived
        self.audioPacketsReceived =
          self.statsData.tableData.audioPacketsReceived
      } else {
        setTimeout(function () {
          self.initStatistics()
        }, 400)
      }
    },

    /* eslint-disable-next-line */
    onChartReady(chart, google) {
      // const query = new google.visualization.Query('https://url-to-spreadsheet...')
      // query.send(response => {
      //   const options = {
      //     // some custom options
      //   }
      //   const data = response.getDataTable()
      //   chart.draw(data, options)
      // })
      // console.log('onChartReady');
    },

    initStatistics() {
      let self = this
      let findedPeer = _.get(self.peers, [self.connId, "remote"])
      if (findedPeer && findedPeer instanceof PeerConnection) {
        self.getStatistics(findedPeer.pc)
        findedPeer.on("addStream", self.handleTrackEventStats)
        self.stopGetStatsLocal = false
      } else {
        self.stopStatistic()
      }
    },

    handleTrackEventStats() {
      let self = this
      setTimeout(function () {
        self.initStatistics()
      }, 400)
    },

    getStatistics: function (peer) {
      // statistics for streams
      var self = this
      const browser = detect()
      var browserName = browser.name

      if (browserName == "firefox" || browserName == "chrome") {
        if (!self.intervalObj) {
          let zeroData = {
            realTimestamp: null,
            progress: 0,
            videoBytesReceived: 0,
            audioBytesReceived: 0,
            videoPacketsLost: 0,
            audioPacketsLost: 0,
            videoPacketsReceived: 0,
            audioPacketsReceived: 0,
          }
          self.generalChartData.push(zeroData)
          self.intervalObj = setInterval(function () {
            peer.getStats(null).then(function (stats) {
              let oldVideoBytesReceived = self.videoBytesReceived
              let oldAudioBytesReceived = self.audioBytesReceived
              let oldVideoPacketsLost = self.videoPacketsLost
              let oldAudioPacketsLost = self.audioPacketsLost
              let oldVideoPacketsReceived = self.videoPacketsReceived
              let oldAudioPacketsReceived = self.audioPacketsReceived
              let timestamp = null
              let fetchStats = []
              if (stats instanceof RTCStatsReport) {
                let statsObj = Object.fromEntries(stats)
                for (let field in statsObj) {
                  fetchStats.push(statsObj[field])
                }
              } else {
                fetchStats = stats
              }
              fetchStats.forEach(function (report) {
                let rep = _.has(report, "value") ? report.value : report
                if (
                  (browserName == "firefox" && rep.type === "inbound-rtp") ||
                  (stats instanceof RTCStatsReport &&
                    rep.type === "inbound-rtp") ||
                  (browserName == "chrome" && rep.type === "ssrc") //&& repVal.transportId === 'Channel-audio-1'
                ) {
                  var packetsLost = parseInt(rep.packetsLost)
                  //var packetsSent = parseInt(report.packetsSent);
                  var packetsReceived = parseInt(rep.packetsReceived)
                  // var audioInputLevel = report.audioInputLevel;
                  var bytesReceived = parseInt(rep.bytesReceived)
                  // var trackId = report.googTrackId; // media stream track id
                  // var isAudio = report.mediaType === 'audio'; // audio or video
                  // var isSending = report.id.indexOf('_send') !== -1; // sender or receiver
                  timestamp = rep.timestamp

                  if (rep.mediaType == "video") {
                    self.videoBytesReceived = bytesReceived
                    self.videoPacketsLost = packetsLost
                    self.videoPacketsReceived = packetsReceived
                  } else if (rep.mediaType == "audio") {
                    self.audioBytesReceived = bytesReceived
                    self.audioPacketsLost = packetsLost
                    self.audioPacketsReceived = packetsReceived
                  }
                }
              })
              let currVideoBytesReceived =
                self.videoBytesReceived - oldVideoBytesReceived
              let currAudioBytesReceived =
                self.audioBytesReceived - oldAudioBytesReceived
              let currVideoPacketsLost =
                self.videoPacketsLost - oldVideoPacketsLost
              let currAudioPacketsLost =
                self.audioPacketsLost - oldAudioPacketsLost
              let currVideoPacketsReceived =
                self.videoPacketsReceived - oldVideoPacketsReceived
              let currAudioPacketsReceived =
                self.audioPacketsReceived - oldAudioPacketsReceived
              self.progressDuration =
                self.progressDuration + parseInt(self.interval)
              let cData = {
                realTimestamp: timestamp,
                progress: self.progressDuration,
                videoBytesReceived: currVideoBytesReceived,
                audioBytesReceived: currAudioBytesReceived,
                videoPacketsLost: currVideoPacketsLost,
                audioPacketsLost: currAudioPacketsLost,
                videoPacketsReceived: currVideoPacketsReceived,
                audioPacketsReceived: currAudioPacketsReceived,
              }
              self.generalChartData.push(cData)
              self.$emit("progress-duration", self.progressDuration)
              if (
                !_.isNull(self.duration) &&
                self.progressDuration >= self.duration
              ) {
                self.stopStatistic().then(() => {
                  self.$emit("statistic-finished", self.getReadyStatsData())
                })
              }
            })
          }, self.interval)
        }
      } else if (browserName == "safari") {
        console.log("safari--")
        if (!self.intervalObj) {
          let zeroData = {
            realTimestamp: null,
            progress: 0,
            videoBytesReceived: 0,
            audioBytesReceived: 0,
            videoPacketsLost: 0,
            audioPacketsLost: 0,
            videoPacketsReceived: 0,
            audioPacketsReceived: 0,
          }
          self.generalChartData.push(zeroData)
          self.intervalObj = setInterval(function () {
            // use medialooks funtion getStats for object RTCPeerConnection
            peer.getStats().then(function (result) {
              let oldVideoBytesReceived = self.videoBytesReceived
              let oldAudioBytesReceived = self.audioBytesReceived
              let oldVideoPacketsLost = self.videoPacketsLost
              let oldAudioPacketsLost = self.audioPacketsLost
              let oldVideoPacketsReceived = self.videoPacketsReceived
              let oldAudioPacketsReceived = self.audioPacketsReceived
              let timestamp = null

              var safariWebrtcNativeStats = Array.from(result.values())

              // Video stream stats
              /* eslint-disable-next-line */
              self.videoBandwith = safariWebrtcNativeStats.filter(function (
                item,
                i
              ) {
                return (
                  item.id.indexOf("RTCInboundRTPVideoStream") >= 0 &&
                  item.type == "inbound-rtp"
                )
              })[0]

              // Audio stream stats
              self.audioBandwith = safariWebrtcNativeStats.filter(function (
                item,
                i
              ) {
                return (
                  item.id.indexOf("RTCInboundRTPAudioStream") >= 0 &&
                  item.type == "inbound-rtp"
                )
              })[0]

              self.videoBytesReceived = parseInt(
                self.videoBandwith?.bytesReceived
              )
              self.audioBytesReceived = parseInt(
                self.audioBandwith?.bytesReceived
              )
              self.videoPacketsLost = parseInt(self.videoBandwith?.packetsLost)
              self.audioPacketsLost = parseInt(self.audioBandwith?.packetsLost)
              self.videoPacketsReceived = parseInt(
                self.videoBandwith?.packetsReceived
              )
              self.audioPacketsReceived = parseInt(
                self.audioBandwith?.packetsReceived
              )

              let currVideoBytesReceived =
                self.videoBytesReceived - oldVideoBytesReceived
              let currAudioBytesReceived =
                self.audioBytesReceived - oldAudioBytesReceived
              let currVideoPacketsLost =
                self.videoPacketsLost - oldVideoPacketsLost
              let currAudioPacketsLost =
                self.audioPacketsLost - oldAudioPacketsLost
              let currVideoPacketsReceived =
                self.videoPacketsReceived - oldVideoPacketsReceived
              let currAudioPacketsReceived =
                self.audioPacketsReceived - oldAudioPacketsReceived
              self.progressDuration =
                self.progressDuration + parseInt(self.interval)
              let cData = {
                realTimestamp: timestamp,
                progress: self.progressDuration,
                videoBytesReceived: currVideoBytesReceived,
                audioBytesReceived: currAudioBytesReceived,
                videoPacketsLost: currVideoPacketsLost,
                audioPacketsLost: currAudioPacketsLost,
                videoPacketsReceived: currVideoPacketsReceived,
                audioPacketsReceived: currAudioPacketsReceived,
              }
              self.generalChartData.push(cData)
              self.$emit("progress-duration", self.progressDuration)
              if (
                !_.isNull(self.duration) &&
                self.progressDuration >= self.duration
              ) {
                self.stopStatistic().then(() => {
                  self.$emit("statistic-finished", self.getReadyStatsData())
                })
              }
            })
          }, self.interval)
        }
      }
    },

    getReadyStatsData() {
      let self = this
      let chartImages = self.getChartsImages()
      return {
        generalChartData: self.generalChartData,
        imgChart: chartImages,
      }
    },

    getChartsImages() {
      let self = this
      return {
        dataReceived:
          !_.isUndefined(self.$refs.drCh) && self.$refs.drCh.chartObject
            ? self.$refs.drCh.chartObject.getImageURI()
            : null,
        packetsLost:
          !_.isUndefined(self.$refs.plCh) && self.$refs.plCh.chartObject
            ? self.$refs.plCh.chartObject.getImageURI()
            : null,
      }
    },

    stopStatistic() {
      let self = this
      return new Promise((resolve) => {
        self.stopGetStatsLocal = true
        if (self.intervalObj) {
          clearInterval(self.intervalObj)
          self.intervalObj = null
        }
        resolve()
      })
    },

    stopStatisticPrematurely() {
      this.stopStatistic()
      this.$emit("statistic-stopped-prematurely", this.getReadyStatsData())
    },

    parserStatsCheckTracks: function (self, result, type) {
      // get bandwith for video or audio streams
      let availableBandwidth = 0
      //let webrtcStatistics = self.webrtcStatistics;
      if (result.bytesReceived) {
        var kilobytes = 0
        if (result.bytesReceived) {
          var prevValueTyped = 0
          if (type == "video") {
            prevValueTyped = self.videoBandwith
          } else if (type == "audio") {
            prevValueTyped = self.audioBandwith
          }
          var bytes = result.bytesReceived - prevValueTyped
          kilobytes = bytes / 1024
        }
        availableBandwidth = kilobytes.toFixed(1)
      }
      return availableBandwidth
    },

    getClientInfoStr(clients, prependStr = "") {
      if (_.has(clients, "locationInfo")) {
        let locationInfo = clients.locationInfo
        let arr = [
          _.get(locationInfo, "country", ""),
          _.get(locationInfo, "region_name", ""),
          _.get(locationInfo, "city", ""),
        ]
        arr = _.filter(arr, (o) => o !== "")
        let locationStr = arr.join("/")
        return [prependStr, clients.ip, locationStr].join(" ")
      } else {
        return clients.ip
      }
    },

    roundToNearestUp(numToRound, numToRoundTo = 10) {
      if (numToRound % numToRoundTo == 0) {
        return numToRound
      }
      return Math.ceil((parseInt(numToRound) + 1) / numToRoundTo) * numToRoundTo
    },

    maxValuesGoogleChart(parsedData) {
      // get max for chart, for avoid negative values for Google line chart
      let self = this
      let dRecHArr = []
      let dRecVArr = []
      _.forEach(parsedData.dReceived, (val, key) => {
        if (key) {
          dRecHArr.push(val[0])
          dRecVArr.push(val[1])
          if (val.length == 3) {
            dRecVArr.push(val[2])
          }
        }
      })
      self.dataReceivedChartOptions.hAxis.viewWindow.max =
        self.roundToNearestUp(_.max(dRecHArr)) || 10
      self.dataReceivedChartOptions.vAxis.viewWindow.max =
        self.roundToNearestUp(_.max(dRecVArr)) || 10

      let pLostHArr = []
      let pLostVArr = []
      _.forEach(parsedData.pLost, (val, key) => {
        if (key) {
          pLostHArr.push(val[0])
          pLostVArr.push(val[1])
          if (val.length == 3) {
            pLostVArr.push(val[2])
          }
        }
      })
      self.packetsLostChartOptions.hAxis.viewWindow.max =
        self.roundToNearestUp(_.max(pLostHArr)) || 10
      self.packetsLostChartOptions.vAxis.viewWindow.max =
        self.roundToNearestUp(_.max(pLostVArr)) || 10
    },

    storeParsedData(data) {
      let received = data.dReceived
      let lost = data.pLost
      let sended = data.pLost
      if (received && received.length > 1) {
        let currentReceived = received[received.length - 1][1]
        this.$store.dispatch("saveWebengineCurrentReceived", currentReceived)
      }
      if (sended && sended.length > 1) {
        let currentSended = sended[sended.length - 1][1]
        this.$store.dispatch("saveWebengineCurrentSended", currentSended)
      }
      if (lost && lost.length > 1) {
        let currentLost = lost[lost.length - 1][1]
        this.$store.dispatch("saveWebengineCurrentLost", currentLost)
      }
    },
  },

  computed: {
    ...mapGetters({
      signalServer: "getFullSignalServerUrl",
      peers: "getWebrtcPeers",
      currentIp: "getCurrentIp",
    }),

    totalBandwith: function () {
      return this.videoBandwith + this.audioBandwith
    },

    totalBytesReceived: function () {
      return this.videoBytesReceived + this.audioBytesReceived
    },

    totalPacketLost: function () {
      return this.videoPacketsLost + this.audioPacketsLost
    },
    videoPercentPacketLost: function () {
      return percentPacketLost(this.videoPacketsReceived, this.videoPacketsLost)
    },
    audioPercentPacketLost: function () {
      return percentPacketLost(this.audioPacketsReceived, this.audioPacketsLost)
    },
    totalPercentPacketLost: function () {
      let totalPL =
        parseFloat(this.videoPercentPacketLost) +
        parseFloat(this.audioPercentPacketLost)
      return decimalsPercentPacketLost(totalPL)
    },
    textVideoBytesReceived: function () {
      return GeneralService.BytesToMBits(this.videoBytesReceived, 2)
    },

    textAudioBytesReceived: function () {
      return GeneralService.BytesToMBits(this.audioBytesReceived, 2)
    },

    textTotalBytesReceived: function () {
      return GeneralService.BytesToMBits(this.totalBytesReceived, 2)
    },

    parsedChartData: function () {
      let self = this
      let parsedData
      if (!_.isNull(self.statsData)) {
        parsedData = _.cloneDeep(self.statsData.chartData)
        let clients = self.statsData.clients
        let findedLocalIp = _.findKey(clients, { ip: self.currentIp })
        if (!_.isUndefined(findedLocalIp)) {
          // If local client statistics is in second line, we move it to first
          if (findedLocalIp == 1) {
            _.forEach(parsedData.dReceived, (val, key) => {
              if (key) {
                let firstLine = parsedData.dReceived[key][1]
                let secondLine = parsedData.dReceived[key][2]
                parsedData.dReceived[key][1] = secondLine
                parsedData.dReceived[key][2] = firstLine
              } else {
                _.set(
                  parsedData,
                  "dReceived[0][1]",
                  self.getClientInfoStr(
                    clients[findedLocalIp],
                    "Received Locally"
                  )
                )
                if (clients.length > 1)
                  _.set(
                    parsedData,
                    "dReceived[0][2]",
                    self.getClientInfoStr(clients[0], "Sent to Remote")
                  )
              }
            })
          } else {
            _.set(
              parsedData,
              "dReceived[0][1]",
              self.getClientInfoStr(clients[0], "Received Locally")
            )
            if (clients.length > 1)
              _.set(
                parsedData,
                "dReceived[0][2]",
                self.getClientInfoStr(clients[1], "Sent to Remote")
              )
          }
        } else {
          _.set(
            parsedData,
            "dReceived[0][1]",
            self.getClientInfoStr(clients[0], "Received to")
          )
          if (clients.length > 1)
            _.set(
              parsedData,
              "dReceived[0][2]",
              self.getClientInfoStr(clients[1], "Received to")
            )
        }
        // convert bytes to Mbits
        _.forEach(parsedData.dReceived, (val, key) => {
          if (key) {
            parsedData.dReceived[key][1] = GeneralService.BytesToMBits(
              val[1],
              2
            )
            if (val.length == 3) {
              parsedData.dReceived[key][2] = GeneralService.BytesToMBits(
                val[2],
                2
              )
            }
          }
        })
      } else {
        parsedData = {
          dReceived: [
            ["Time", "MBits received"],
            [0, 0],
          ],
          pLost: [
            ["Time", "Packets lost"],
            [0, 0],
          ],
        }
        if (self.generalChartData.length > 0) {
          let firstProgressTime = self.generalChartData[0].progress
          _.forEach(self.generalChartData, function (val) {
            let sec = parseInt((val.progress - firstProgressTime) / 1000)
            let totalReceivedBytes = parseInt(
              val.videoBytesReceived + val.audioBytesReceived
            )
            let totalReceivedMbits =
              totalReceivedBytes / self.intervalSeconds / 125000
            parsedData["dReceived"].push([sec, totalReceivedMbits])
            parsedData["pLost"].push([
              sec,
              val.videoPacketsLost + val.audioPacketsLost,
            ])
          })
        }
      }
      self.maxValuesGoogleChart(parsedData)
      if (self.saveStatsInStore) {
        self.storeParsedData(parsedData)
      }
      return parsedData
    },

    drChartOptions: function () {
      if (this.theme == "dark") {
        return _.merge(this.dataReceivedChartOptions, this.darkThemeOptions)
      }
      return this.dataReceivedChartOptions
    },

    plChartOptions: function () {
      if (this.theme == "dark") {
        return _.merge(this.packetsLostChartOptions, this.darkThemeOptions)
      }
      return this.packetsLostChartOptions
    },

    intervalSeconds: function () {
      return this.interval / 1000
    },
  },
}
</script>

<style lang="scss" scoped>
.statistic-container {
  position: relative;
}
.testResultTable {
  td,
  th {
    padding: 4px 8px;
  }
}
</style>
